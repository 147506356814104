.highlight svg path {
  stroke: #4a49cb;
}
.highlight .text {
  color: #4a49cb;
}
.highlightFill svg path {
  fill: #4a49cb;
}
.highlightFill .text {
  color: #4a49cb;
}
.hover:hover svg path {
  stroke: #4a49cb;
}
.hover:hover .text {
  color: #4a49cb;
}
.hoverFill:hover svg path {
  fill: #4a49cb;
}
.hoverFill:hover .text {
  color: #4a49cb;
}

.text {
  color: #667085;
}

.active svg path {
  stroke: #3232b1;
}
.active svg rect {
  fill: #f1f1fb;
}
.activeFill svg path {
  fill: #3232b1;
}
.error svg path {
  stroke: red;
}
.errorFill svg path {
  fill: red;
}
.error svg rect {
  stroke: rgba(255, 0, 0, 0.1);
}
.errorFill svg rect {
  fill: rgba(255, 0, 0, 0.1);
}
.active .text {
  color: #3232b1;
}
.navbarIcon svg rect {
  fill: #f1f1fb;
}
.navbarIcon svg rect {
  fill: #f1f1fb;
}
.untransparent svg path {
  opacity: 1;
}

.disabled svg path {
  opacity: 0.3;
}
