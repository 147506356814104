.day {
  &[data-selected] {
    background-color: #4040c9;
    color: white;
  }
}
.customStyles {
  background-color: #4040c9 !important;
  color: white;
}
