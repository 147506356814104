.root {
  &[data-variant="primary"] {
    background-color: #4a49cb;
    font-weight: 600;
    &[data-disabled] {
      background-color: #eaecf0;
      color: lightgrey !important;
    }
    &[data-loading] {
      background-color: #4a49cb;
    }
  }
  &[data-variant="light-primary"] {
    background-color: #f1f1fb;
    font-weight: 600;
    color: #4a49cb;
    &[data-disabled] {
      background-color: #eaecf0;
      color: lightgrey !important;
    }
    &[data-loading] {
      background-color: #f1f1fb;
    }
  }
  &[data-variant="light-primary"] svg path {
    stroke: #4a49cb;
  }
  &[data-variant="secondary"] {
    background-color: transparent;
    color: #4a49cb;
    font-weight: 600;
    &[data-disabled] {
      background-color: #f4f5f7;
      color: lightgrey !important;
    }
    &[data-loading] {
      background-color: #f1f1fb;
    }
  }
  &[data-variant="textBased"] {
    background-color: transparent;
    color: #4a49cb;
    font-weight: 600;
    &[data-disabled] {
      color: lightgrey !important;
    }
  }
  &[data-variant="destructive"] {
    background-color: #fef3f2;
    color: #b42318;
    font-weight: 600;
    &[data-disabled] {
      opacity: 0.5;
    }
    &[data-loading] {
      background-color: #b42318;
      opacity: 0.5;
    }
  }

  &[data-variant="link"] {
    background-color: #f1f1fb;
    color: #4a49cb;
    font-weight: 600;
  }
  &[data-variant="tool"] {
    background-color: white;
    color: #4a49cb;
    font-weight: 500;
    border: 1px solid #e9e9e9;
    &[data-disabled] {
      opacity: 0.5;
    }
    &[data-loading] {
      background-color: white;
      opacity: 0.5;
    }
  }
}
.root:hover {
  &[data-variant="primary"] {
    background-color: #3232b1;
    &[data-disabled] {
      background-color: #eaecf0;
      color: lightgrey !important;
    }
    &[data-loading] {
      background-color: #4a49cb;
    }
  }
  &[data-variant="light-primary"] {
    background-color: #3232b1;
    color: white;

    &[data-disabled] {
      background-color: #eaecf0;
      color: lightgrey !important;
    }
  }
  &[data-variant="light-primary"] svg path {
    stroke: white;
  }
  &[data-variant="secondary"] {
    background-color: #f1f1fb;
    color: #4a49cb;
    &[data-loading] {
      background-color: #f1f1fb;
    }
  }
  &[data-variant="textBased"] {
    background-color: transparent;
    color: #4a49cb;
  }
  &[data-variant="destructive"] {
    background-color: #fee4e2;
    color: #b42318;
  }
  &[data-variant="link"] {
    background-color: #4a49cb;
    color: white;
  }
  &[data-variant="link"] svg path {
    stroke: white;
  }
  &[data-variant="tool"] {
    background-color: #e4e7ec;
    color: #4a49cb;
    font-weight: 500;
  }
}

.root:disabled svg path {
  stroke: #667085;
  opacity: 0.3;
}
