::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Колір скроллбара */
::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 10px;
}

/* Колір при наведенні на скроллбар */
::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

html {
  overflow: hidden;
  box-sizing: border-box;
}
